import { ReactNode } from 'react'
import * as Select from '@radix-ui/react-select'
import styled, { css } from 'styled-components'
import { BorderRadiuses, Transitions, zIndexes } from 'styles'
import { ArrowDropDownIcon } from 'components/atoms/Icons'
import { InputLabel } from 'components/atoms/Input'
import { DropdownItem } from './DropdownItem'
import { StaticImageData } from 'next/image'

interface OptionWithIcon {
  text: string
  icon: string | StaticImageData
}

export interface OptionWithType<Type = string> {
  value: Type
  text: string | ReactNode
}

// TODO: remove readonly string[]
type OptionsType<Type = string> = string[] | OptionWithIcon[] | readonly string[] | OptionWithType<Type>[]

export interface DropdownProps<Type = string> {
  id: string
  options: OptionsType<Type>
  label?: ReactNode
  placeholder?: ReactNode
  value?: Type
  defaultValue?: Type
  error?: string
  onChange?: (value: Type) => void
  disabled?: boolean
  maxWidth?: number
  maxListHeight?: number
  isRequired?: boolean
  className?: string
  fitContent?: boolean
}

export const DropdownMenu = ({
  id,
  options,
  label,
  placeholder,
  value,
  defaultValue,
  error,
  onChange,
  disabled,
  maxWidth,
  isRequired,
  className,
  fitContent,
  maxListHeight,
}: DropdownProps) => {
  return (
    <DropdownWrapper disabled={disabled} maxWidth={maxWidth} className={className}>
      {label && (
        <InputLabel htmlFor={id} disabled={disabled} isRequired={isRequired}>
          {label}
        </InputLabel>
      )}
      <Select.Root value={value} defaultValue={defaultValue} disabled={disabled} onValueChange={onChange}>
        <DropdownButton id={id} error={error} className={className}>
          <DropdownValue placeholder={placeholder && <Placeholder>{placeholder}</Placeholder>} />
          <Select.Icon asChild>
            <DropdownArrow size={24} className="arrow" />
          </Select.Icon>
        </DropdownButton>
        <Select.Portal>
          <SelectContent
            position="popper"
            sideOffset={fitContent ? 14 : 2}
            align={fitContent ? 'center' : 'start'}
            asChild
          >
            <DropdownList fitContent={fitContent}>
              <OptionsList options={options} disabled={disabled} maxListHeight={maxListHeight} />
            </DropdownList>
          </SelectContent>
        </Select.Portal>
      </Select.Root>
    </DropdownWrapper>
  )
}

const OptionsList = ({
  options,
  disabled,
  maxListHeight,
}: {
  options: OptionsType
  disabled?: boolean
  maxListHeight?: number
}) => {
  return (
    <Viewport $maxListHeight={maxListHeight}>
      {options.map((option, i) => {
        if (isOptionWithIcon(option)) {
          return <DropdownItem key={i} value={option.text} text={option.text} icon={option.icon} disabled={disabled} />
        }
        if (isOptionWithType(option)) {
          return <DropdownItem key={i} value={option.value} text={option.text} disabled={disabled} />
        }
        return <DropdownItem key={i} value={option} text={option} disabled={disabled} />
      })}
    </Viewport>
  )
}

const isOptionWithIcon = (option: string | OptionWithIcon | OptionWithType): option is OptionWithIcon => {
  return typeof option !== 'string' && 'icon' in option
}
const isOptionWithType = (option: string | OptionWithIcon | OptionWithType): option is OptionWithType => {
  return typeof option !== 'string' && 'value' in option
}

export const DropdownWrapper = styled.div<Pick<DropdownProps, 'disabled' | 'maxWidth'>>`
  display: flex;
  position: relative;
  align-items: center;
  width: 100%;
  max-width: ${({ maxWidth }) => (maxWidth ? maxWidth + 'px' : '100%')};
  color: ${({ disabled, theme }) => (disabled ? theme.colors.Iron : theme.colors.Corduroy)};

  &:focus-within {
    color: ${({ disabled, theme }) => (disabled ? theme.colors.Iron : theme.colors.HeavyMetal)};
  }

  &:hover {
    color: ${({ disabled, theme }) => (disabled ? theme.colors.Iron : theme.colors.HeavyMetal)};
  }

  &.inner {
    width: fit-content;
    border: none;
  }
`

const SelectContent = styled(Select.Content)`
  position: relative;
  z-index: ${zIndexes.banner};
`

const DropdownArrow = styled(ArrowDropDownIcon)`
  transition: ${Transitions.all};
  transform-origin: center center;
`

export const DropdownButton = styled(Select.Trigger)<{ error?: string }>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 48px;
  padding: 0 16px;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  border-width: 1px;
  border-style: solid;
  border-color: ${({ error, disabled, theme }) => (error && !disabled ? theme.colors.PersianRed : theme.colors.Iron)};
  border-radius: ${BorderRadiuses.s};
  background-color: ${({ theme }) => theme.colors.White};
  outline: none;
  color: ${({ disabled, theme }) => (disabled ? theme.colors.Iron : theme.colors.HeavyMetal)};
  transition: ${Transitions.all};
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};

  &:hover {
    border-color: ${({ error, theme }) => (error ? theme.colors.PersianRed : theme.colors.HeavyMetal)};
    color: ${({ theme }) => theme.colors.HeavyMetal};
  }

  &:focus-visible {
    border-color: ${({ theme }) => theme.colors.HeavyMetal};
    color: ${({ theme }) => theme.colors.HeavyMetal};
  }

  &[data-state='open'] {
    border-color: ${({ theme }) => theme.colors.HeavyMetal};
    color: ${({ theme }) => theme.colors.HeavyMetal};

    & .arrow {
      transform: scaleY(-1);
    }
  }

  &[data-disabled] {
    color: ${({ theme }) => theme.colors.Iron};
    background-color: ${({ theme }) => theme.colors.GrayNurse};
    border-color: ${({ theme }) => theme.colors.Iron};
    cursor: not-allowed;
  }

  &.inner {
    height: 24px;
    border: none;
    padding: 0;
    color: ${({ disabled, theme }) => (disabled ? theme.colors.Iron : theme.colors.Edward)};
  }

  &.inner[data-disabled] {
    background-color: ${({ theme }) => theme.colors.GrayNurse};
  }
`

const DropdownValue = styled(Select.Value)`
  width: 100%;
  flex: 1;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
`

const Placeholder = styled.span`
  color: ${({ theme }) => theme.colors.Iron};
`

const DropdownList = styled.div<Pick<DropdownProps, 'fitContent'>>`
  display: flex;
  flex-direction: column;
  padding: 4px;
  width: ${({ fitContent }) => (fitContent ? 'fit-content' : 'var(--radix-popper-anchor-width)')};
  border: 1px solid ${({ theme }) => theme.colors.HeavyMetal};
  border-radius: ${BorderRadiuses.s};
  background-color: ${({ theme }) => theme.colors.White};
`

const Viewport = styled(Select.Viewport)<{ $maxListHeight?: number }>`
  display: flex;
  flex-direction: column;
  ${({ $maxListHeight }) =>
    $maxListHeight &&
    css`
      overflow-y: auto;
      max-height: ${$maxListHeight}px;
    `}
`
